/* Mixins */
.permission-service-dialog-component .menu-item-wrapper {
  border: solid 1px #d9d9d9;
}
.permission-service-dialog-component .menu-item-wrapper .menu-item-header {
  padding: 12px 16px;
  background-color: #fafafa;
  font-size: 16px;
  font-weight: bold;
}
.permission-service-dialog-component .ant-descriptions-item-label {
  width: 250px;
}